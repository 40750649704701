import React, {useRef} from 'react'
import axios from 'axios'
import { motion, AnimatePresence } from "framer-motion"
import { useState } from 'react';
import { useEffect } from 'react';

function Events() {
  const [change, setChange] = useState(0)
  const [data, setData] = useState([])
    const ref = useRef(0);

  const fetchData = async () => {
    await axios.get(`${process.env.REACT_APP_CLIENT_HOST}/site/home/blogs/`)
      .then((res) => {
        setData(res.data)
      }).catch((err) => {
        console.log(err)
      });
  }

  useEffect(() => {
    fetchData()
  }, [])


  return (
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Bloglar</h2>
        <p></p>
      </div>

      {/*Event Slider*/}
      <div className="events-slider swiper-container" data-aos="fade-up" data-aos-delay={100}>
        <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="row event-item">
                <AnimatePresence>
                  <motion.div className="col-lg-4" initial={{ opacity: 0, x: 0.5 }}
                            key={ref.current} animate={ref.current === 0 ? {opacity: 1, scale: 1} :{ opacity: 1, x: 1 }}
                              transition={{ type: "spring", stiffness: 60 }}
                            >
                    {data[change] ? <img src={data[change].image} key={data[change].id} className="img-fluid imageCjasteBlog" alt="" /> : ''}
                  </motion.div>
                </AnimatePresence>

                <div className="col-lg-8 pt-4 pt-lg-0 content">
                  <h3>
                    {data[change] ? data[change].title : ''}
                  </h3>
                  <p className="fst-italic">
                    {data[change] ? data[change].text : ''}
                  </p>
                </div>
              </div>
            </div>
        </div>
        <div className="blogBottomPaginate">
          {data.map((item, index) => (
            <div key={index} onClick={() => {setChange(index)
                ref.current ++}} className={`${index === change ? "btnOnChange disabled" : "btnOnChange "} btnOnChange `}>
              {index + 1}
            </div>
          ))}
        </div>
        <div className="swiper-pagination" />
      </div>
      {/*Event Slider Ends*/}
    </div>
  )
}

export default Events