import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

function Gallery() {
  const [data, setData] = useState([])

  const fetchData = async () => {
    await axios.get(`${process.env.REACT_APP_CLIENT_HOST}/site/home/gallery/`)
      .then((res) => {
        setData(res.data)
      }).catch((err) => {
        console.log(err)
      });
  }


  useEffect(() => {
    fetchData()
  }, [])


  return (
    <>
  {data.map((item) => (
      <div key={item.id} className="col-lg-3 col-md-4">
          <div className="gallery-item">
           <Zoom>
              <img src={item.image} alt="" className="img-fluid" />
          </Zoom>
          </div>
        </div>
      ))}
    </>
  )
}

export default Gallery