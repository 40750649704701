import React, { useEffect, useState } from 'react'
import axios from 'axios';

function About() {
  const [data, setData] = useState([])

  const fetchData = async () => {
    await axios.get(`${process.env.REACT_APP_CLIENT_HOST}/site/home/abouts/`)
      .then((res) => {
        setData(res.data)
      }).catch((err) => {
        console.log(err)
      });
  }


  useEffect(() => {
    fetchData()
  }, [])

  return (
<>
        <h3>Biz haqimizda</h3>
        <p>
          {data[0] ? data[0].text : ''}
        </p>
      </>
  )
}

export default About