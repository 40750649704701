import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import About from './components/About';
import Specials from './components/Specials';
import Events from './components/Events';
import Gallery from './components/Gallery';
// import Menu from './components/Menu';


const root = ReactDOM.createRoot(document.getElementById('aboutCjaste'));
root.render(
  <React.StrictMode>
    <About />
  </React.StrictMode>
);

// const root5 = ReactDOM.createRoot(document.getElementById('menuCjaste'));
// root5.render(
//   <React.StrictMode>
//     <Menu />
//   </React.StrictMode>
// );

const root2 = ReactDOM.createRoot(document.getElementById('specials'));
root2.render(
  <React.StrictMode>
    <Specials />
  </React.StrictMode>
);


const root3 = ReactDOM.createRoot(document.getElementById('events'));
root3.render(
  <React.StrictMode>
    <Events />
  </React.StrictMode>
);



const root4 = ReactDOM.createRoot(document.getElementById('galleryCjaste'));
root4.render(
  <React.StrictMode>
    <Gallery />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
