import React from 'react'
import axios from 'axios'
import { useEffect } from 'react';
import { useState } from 'react';

function Specials() {
  const [data, setData] = useState([])
  const [change, setChange] = useState(0)

  const fetchData = async () => {
    await axios.get(`${process.env.REACT_APP_CLIENT_HOST}/site/home/offer-controls/`)
      .then((res) => {
        setData(res.data)
      }).catch((err) => {
        console.log(err)
      });
  }


  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Siz uchun</h2>
        <p>Maxsus takliflar</p>
      </div>
      <div className="row" data-aos="fade-up" data-aos-delay={100}>
        <div className="col-lg-3">
          <ul className="nav nav-tabs flex-column">
            {/* <li className="nav-item">
            <a className="nav-link active show" data-bs-toggle="tab" href="#tab-1">Modi sit est</a>
          </li> */}
            {data.map((item, index) => (
              <li key={item.id} className={`nav-item ${item.id === change ? "active show" : ""}`}>
                <a className="nav-link" data-bs-toggle="tab" onChange={() => setChange(item.id)} href={`#tab-${item.id}`}>
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-lg-9 mt-4 mt-lg-0">
          <div className="tab-content">

            {data.map((item, index) => (
              <div key={index} className={`tab-pane ${item.id === change ? "active show" : ""}`} id={`tab-${item.id}`}>
                <div className="row">
                  <div className="col-lg-6 details order-2 order-lg-1">
                    <h3>{item.title}</h3>
                    {/* <p className="fst-italic">Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka</p> */}
                    <p>
                      {item.text}
                    </p>
                  </div>
                  <div className="col-lg-6 text-center order-1 order-lg-2">
                    <img src={item.image} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </div>
  )
}

export default Specials